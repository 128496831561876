#skills {
    position: relative;
    min-height: 75vh;
}

.app__skills-section {
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin: 5rem 0;
}

.app__skills-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;

    @media screen and (min-width: 2000px) {
        margin: 2rem auto;
        max-width: 40%;
    }   
    
    @media screen and (min-width: 1200px) and (max-width: 2000px){
        margin: 2rem auto;
        max-width: 50%;
    }   
    
    @media screen and (min-width: 1000px) and (max-width: 1200px) {
        margin: 2rem auto;
        max-width: 80%;
    }    


    @media screen and (max-width: 1000px) {
        width: 100%;
        flex-direction: column;
    }
}

.app__skills-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    @media screen and (max-width: 1000px) {
        margin-right: 0;
        justify-content: center;
        align-items: center;;
    }
}

.app__skills-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem;

    transition: all 0.3s ease-in-out;

    div {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: var(--item-color);
        margin: 1rem;
        
        img{
            width: 50%;
            height: 50%;
        }

        &:hover {
            box-shadow: 0 0 25px var(--grey-color);
        }

        @media screen and (min-height: 2000px) {
            width: 150px;
            height: 150px;
        }

        @media screen and (max-height: 450px) {
            width: 70px;
            height: 70px;
        }
    }

    p { 
        font-weight: 500;
        margin-top: 0.5rem;
        text-align: center;
    }

    @media screen and (min-width: 2000px) {
        margin: 1rem 2rem;

        p { 
            margin-top: 1rem;
        }
    }
}