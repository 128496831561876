#home {
    position: relative;
    min-height: 100vh;
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 6rem 2rem 0;
    margin-bottom: 5rem;

    @media screen and (min-width: 2000px) {
        padding-top: 2rem;
    }
    @media screen and (max-width: 1200px) {
     flex-direction: column;
    }
    @media screen and (max-width: 400px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    width: 50%;

    margin: 0 1rem;

    @media screen and (max-width: 1200px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .badge-cmp, 
    .tag-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        
        box-shadow: 0 0 20px rgba(0, 0, 0, .6);

        @media screen and (max-width: 1000px) {
            width: 95%;
        }
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;

        p {
            width: 100%;
            text-align: left;
        }
    }

    .badge-cmp-oneliner {
        color: rgb(114, 113, 113);
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    margin-left: 1rem;

    div {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: var(--primary-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, .6);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1) {
        width: 100px;
        height: 100px;
    }
    div:nth-child(2) {
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }
    div:nth-child(3) {
        width: 70px;
        height: 70px;
    }

    @media screen and (min-with: 2000px) {
        div:nth-child(1) {
            width: 400px;
            height: 400px;
        }
        div:nth-child(2) {
            width: 170px;
            height: 170px;
        }
        div:nth-child(3) {
            width: 200px;
            height: 200px;
        }
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }
    }
}