#projects {
    position: relative;
    min-height: 75vh;
}

.app__projects-section {
    margin: 0 auto;
    max-width: 1200px;

    h2 {
        text-align: center;
    }
}

.app__projects-portfolio {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 15px;
    position: relative;
    margin-top: 50px;

    .app__project-item {
        margin: 2rem 1rem;
        padding: 1rem 2rem;
        border-radius: 0.5rem;
        background-color: var(--item-color);
        color: #000;

        transition: all 0.3s ease;

        &:hover {
            background-color: var(--secondary-color);
            scale: 1.03;
            color: #fff;

            p {
                color: lightgrey;
            }
        }

        @media screen and (max-width: 400px) {
            width: 95%;
            margin: 1rem auto;    
        }
    }

    @media screen and (max-width: 1080px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.app__project-header {
    display: flex;
    width: 100%;
    height: 20%;
    justify-content: space-between;
    padding: 1rem 0;
    align-items: center;

    a {
        text-decoration: none;
        color: inherit;
    }

    h4 {
        font-size: 1.5rem;
        font-weight: 800;
        overflow: hidden;
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            margin-top: 1rem;
            white-space: normal;
            overflow: visible;
        }
    }
}

.app__project-content {
    padding: 0.5rem 0;
    width: 100%;
    height: 70%;
    position: relative;
    flex-direction: column;

    p {
        font-size: 1.5rem;
        text-align: left;
        line-height: 1.5;
        color: var(--grey-color);
        max-height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        @media screen and (min-width: 2000px) {
            font-size: 1.5rem;
        }
    }
}

.app__project-technologies {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 10%;

    p {
        padding: 0 0rem 0.5rem 0.5rem;
        font-style: italic;
    }
}
