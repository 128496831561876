#contact {
    position: relative;
}

.app__footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    max-width: 1200px;
    margin: 0 auto;
    p {
        color: var(--opposite-primary-color);
        text-align: center;
        font-weight: 800;
        margin: 1rem 10rem;
        line-height: 1.5;

        @media screen and (max-width: 1200px) {
            margin: 1rem 5rem;
        }
    }
}