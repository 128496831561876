@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  /* --primary-color:rgba(242, 234, 199, 0.908); */
  --primary-color: #edf2f8;
  --opposite-primary-color: rgba(13, 21, 56, 0.908);
  --secondary-color: #00539CFF;
  --opposite-secondary-color: #EEA47FFF;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --item-color: #b3bbc7;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--primary-color);
    z-index: -1;
}