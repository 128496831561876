#work {
    position: relative;
    min-height: 75vh;
}

.app__work-section {    
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin: 5rem 0;
}

.app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;

    margin: 1rem 0 2rem;

    .app__work-filter-item {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: var(--item-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, .1);
        color: #000;
        font-size: 20px;
        font-weight: 800;

        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover {
            scale: 1.05;
            box-shadow: 0 0 40px rgba(0, 0, 0, .1);
        }

        @media screen and (min-width: 2000px) {
            font-size: 25px;
            padding: 1rem 2rem;
            border-radius: 0.85;
        }

        @media screen and (max-width: 1000px) and (min-width: 500px){
            min-width: 500px;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
        }
    }

    .item-active {
        background-color: var(--secondary-color);
        color: var(--lightGray-color);
    }

    .item-inactive {
        display: none;
    }
}

.app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .app__work-item {
        flex-direction: column;
        margin: 4rem;
        padding: 2rem;
        border-radius: 50px;
        box-shadow: 0 0 20px rgba(0, 0, 0, .3);

        .app__work-content-time {
            margin: 1rem;
        }

        @media screen and (max-width: 1000px) {
            padding: 1rem;
            margin: 1rem;
        }
    }

    .item-inactive {
        display: none;
    }
}

.app__work-content-responsibilities-list {

    li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        text-decoration: underline;
        @media screen and (max-width: 1000px) {
            justify-content: flex-start;
            align-items: flex-start;
            margin: 1rem 1rem
        }
    }
}